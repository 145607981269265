import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import router from "../router";

export const initialState = {
  token: "",
  pass: "",
  islogedIn: "",
  memberInfo: "",
  selectedMotor: "",
  royalComment: "",
  //田海追加
  infomation: "",
  contact: "",
  icon: [
    { img: require("@/assets/images/parts/ico01.svg") },
    { img: require("@/assets/images/parts/ico02.svg") },
    { img: require("@/assets/images/parts/ico03.svg") },
    { img: require("@/assets/images/parts/ico04.svg") },
    { img: require("@/assets/images/parts/ico05.svg") },
    { img: require("@/assets/images/parts/ico06.svg") },
    { img: require("@/assets/images/parts/ico07.svg") },
    { img: require("@/assets/images/parts/ico08.svg") },
    { img: require("@/assets/images/parts/ico09.svg") },
    { img: require("@/assets/images/parts/ico10.svg") },
    { img: require("@/assets/images/parts/ico11.svg") },
    { img: require("@/assets/images/parts/ico12.svg") },
    { img: require("@/assets/images/parts/ico13.svg") },
    { img: require("@/assets/images/parts/ico14.svg") },
    { img: require("@/assets/images/parts/ico15.svg") },
    { img: require("@/assets/images/parts/ico16.svg") },
    { img: require("@/assets/images/parts/ico17.svg") },
    { img: require("@/assets/images/parts/ico18.svg") },
  ],
  royalIcon: [
    { img: require("@/assets/images/parts/ico19.svg") },
    { img: require("@/assets/images/parts/ico20.jpg") },
    { img: require("@/assets/images/parts/ico21.jpg") },
    { img: require("@/assets/images/parts/ico22.jpg") },
    { img: require("@/assets/images/parts/ico23.jpg") },
    { img: require("@/assets/images/parts/ico24.jpg") },
    { img: require("@/assets/images/parts/ico25.jpg") },
    { img: require("@/assets/images/parts/ico26.jpg") },
    { img: require("@/assets/images/parts/ico27.jpg") },
    { img: require("@/assets/images/parts/ico28.jpg") },
    { img: require("@/assets/images/parts/ico29.jpg") },
    { img: require("@/assets/images/parts/ico30.jpg") },
    { img: require("@/assets/images/parts/ico31.jpg") },
    { img: require("@/assets/images/parts/ico32.jpg") },
    { img: require("@/assets/images/parts/ico33.jpg") },
  ],
  motorImg: {
    "011": require("@/assets/images/motor/motor_no11.jpg"),
    "012": require("@/assets/images/motor/motor_no12.jpg"),
    "013": require("@/assets/images/motor/motor_no13.jpg"),
    "014": require("@/assets/images/motor/motor_no14.jpg"),
    "015": require("@/assets/images/motor/motor_no15.jpg"),
    "016": require("@/assets/images/motor/motor_no16.jpg"),
    "017": require("@/assets/images/motor/motor_no17.jpg"),
    "018": require("@/assets/images/motor/motor_no18.jpg"),
    "019": require("@/assets/images/motor/motor_no19.jpg"),
    "020": require("@/assets/images/motor/motor_no20.jpg"),
    "021": require("@/assets/images/motor/motor_no21.jpg"),
    "022": require("@/assets/images/motor/motor_no22.jpg"),
    "023": require("@/assets/images/motor/motor_no23.jpg"),
    "024": require("@/assets/images/motor/motor_no24.jpg"),
    "025": require("@/assets/images/motor/motor_no25.jpg"),
    "026": require("@/assets/images/motor/motor_no26.jpg"),
    "027": require("@/assets/images/motor/motor_no27.jpg"),
    "028": require("@/assets/images/motor/motor_no28.jpg"),
    "029": require("@/assets/images/motor/motor_no29.jpg"),
    "030": require("@/assets/images/motor/motor_no30.jpg"),
    "031": require("@/assets/images/motor/motor_no31.jpg"),
    "032": require("@/assets/images/motor/motor_no32.jpg"),
    "033": require("@/assets/images/motor/motor_no33.jpg"),
    "034": require("@/assets/images/motor/motor_no34.jpg"),
    "035": require("@/assets/images/motor/motor_no35.jpg"),
    "036": require("@/assets/images/motor/motor_no36.jpg"),
    "037": require("@/assets/images/motor/motor_no37.jpg"),
    "038": require("@/assets/images/motor/motor_no38.jpg"),
    "039": require("@/assets/images/motor/motor_no39.jpg"),
    "040": require("@/assets/images/motor/motor_no40.jpg"),
    "041": require("@/assets/images/motor/motor_no41.jpg"),
    "043": require("@/assets/images/motor/motor_no43.jpg"),
    "045": require("@/assets/images/motor/motor_no45.jpg"),
    "046": require("@/assets/images/motor/motor_no46.jpg"),
    "047": require("@/assets/images/motor/motor_no47.jpg"),
    "048": require("@/assets/images/motor/motor_no48.jpg"),
    "049": require("@/assets/images/motor/motor_no49.jpg"),
    "050": require("@/assets/images/motor/motor_no50.jpg"),
    "051": require("@/assets/images/motor/motor_no51.jpg"),
    "052": require("@/assets/images/motor/motor_no52.jpg"),
    "053": require("@/assets/images/motor/motor_no53.jpg"),
    "054": require("@/assets/images/motor/motor_no54.jpg"),
    "055": require("@/assets/images/motor/motor_no55.jpg"),
    "056": require("@/assets/images/motor/motor_no56.jpg"),
    "057": require("@/assets/images/motor/motor_no57.jpg"),
    "058": require("@/assets/images/motor/motor_no58.jpg"),
    "059": require("@/assets/images/motor/motor_no59.jpg"),
    "060": require("@/assets/images/motor/motor_no60.jpg"),
    "061": require("@/assets/images/motor/motor_no61.jpg"),
    "062": require("@/assets/images/motor/motor_no62.jpg"),
    "063": require("@/assets/images/motor/motor_no63.jpg"),
    "064": require("@/assets/images/motor/motor_no64.jpg"),
    "065": require("@/assets/images/motor/motor_no65.jpg"),
    "066": require("@/assets/images/motor/motor_no66.jpg"),
    "067": require("@/assets/images/motor/motor_no67.jpg"),
    "068": require("@/assets/images/motor/motor_no68.jpg"),
    "069": require("@/assets/images/motor/motor_no69.jpg"),
    "070": require("@/assets/images/motor/motor_no70.jpg"),
    "071": require("@/assets/images/motor/motor_no71.jpg"),
    "072": require("@/assets/images/motor/motor_no72.jpg"),
    "073": require("@/assets/images/motor/motor_no73.jpg"),
    "074": require("@/assets/images/motor/motor_no74.jpg"),
    "075": require("@/assets/images/motor/motor_no75.jpg"),
    "076": require("@/assets/images/motor/motor_no76.jpg"),
    "077": require("@/assets/images/motor/motor_no77.jpg"),
  },
  mortorList: "",
  motorDetail: "",
  raceInfo: "",
  royalUser: "",
  royalUserList: "",
  royalUserSelectMotor: "",
  registUser: "",
  // 倉田
  ownerRanking: "",
  ownerDetail: Object,
  ownerRankingChart: "",
  rankingChart: "",
  motorRanking: "",
  winRanking: "",
  // apiEndpoint: "https://stg-api.gama-mog.com",
  apiEndpoint: "https://api.gama-mog.com",
};

const mutations = {
  //state初期化
  RESET_VUEX_STATE(state) {
    Object.assign(state, JSON.parse(localStorage.getItem("initialState")));
  },
  updateLogin(state, obj) {
    state.token = obj.token;
    state.pass = obj.pass;
    state.islogedIn = true;
  },
  updateMember(state, obj) {
    state.memberInfo = obj;
  },
  updateRoyalComment(state, obj) {
    state.royalComment = obj;
  },
  updateIcon(state, obj) {
    state.memberInfo.icon = obj;
  },
  updateContact(state, obj) {
    state.contact = obj;
  },
  updatePassword(state, obj) {
    state.pass = obj;
  },
  updateMail(state, obj) {
    state.memberInfo.mail = obj;
  },
  updateTelnumber(state, obj) {
    state.memberInfo.tel_number = obj;
  },
  updateMailmagazine(state, obj) {
    state.memberInfo.mail_recive_flg = obj;
  },
  updateMotorList(state, response) {
    state.mortorList = response;
  },
  updateMotorDetail(state, response) {
    state.motorDetail = response;
  },
  updateSelectMotor(state, response) {
    state.selectedMotor = response;
  },
  updateLastRaceInfo(state, response) {
    state.raceInfo = response;
  },
  updateRoyalUser(state, response) {
    state.royalUser = response;
  },
  updateRoyalUserList(state, response) {
    state.royalUserList = response;
  },
  updateRoyalUserSelectMotor(state, response) {
    state.royalUserSelectMotor = response;
  },
  updateRankingOwnerInfo(state, response) {
    state.ownerRanking = response;
  },
  updateOwnerDetail(state, response) {
    state.ownerDetail = response.member_info.info;
    state.ownerDetail.motors = response.selected_motor.motors;
  },
  updateRankingMotorInfo(state, response) {
    state.motorRanking = response;
  },
  updateRankingWinInfo(state, response) {
    state.winRanking = response;
  },
  updateInformation(state, response) {
    state.information = response;
  },
  updateOwnerRankingChart(state, response) {
    state.ownerRankingChart = response;
  },
  updateRankingChart(state, response) {
    state.rankingChart = response;
  },
  updateRegistRoyalUser(state, response) {
    state.registUser = response;
  },
};

const actions = {
  memberInfo(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/member_info",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateMember", response.data.info);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  registComment(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/member_comment",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { comment: obj },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateRoyalComment", response.data.comment);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  login(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/login",
        params: { email: obj.mail, password: obj.pass },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("RESET_VUEX_STATE");
            obj.token = response.data.token;
            context.commit("updateLogin", obj);
            context.dispatch("memberInfo");
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  tokenCheck(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/token_check",
        params: { token: obj },
      })
        .then((response) => {
          if (!response.data.result) {
            alert("ログイン期限が切れました。ログインし直してください。");
            context.commit("RESET_VUEX_STATE");
            router.push("/login");
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  regist(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/regist",
        params: {
          nickname: obj.name,
          email: obj.mail,
          password: obj.pass,
          mail_recieve_flg: obj.magazine,
          tel_number: obj.tel_number,
        },
      })
        .then((response) => {
          if (response.data.result) {
            // obj.token = response.data.token;
            context.dispatch("login", obj);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  changeIcon(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/icon_change",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { icon: obj },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateIcon", obj);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //田海追加
  changePassword(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/password_change",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { old_password: obj.old_pass, new_password: obj.pass },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updatePassword", obj.pass);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  changeMail(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/member_email_change",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { email: obj.mail },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateMail", obj.mail);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  changeTelnumber(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/regist_tel_number",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { tel_number: obj.tel_number },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateTelnumber", obj.tel_number);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  changeMailmagazine(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/mail_recieve_change",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { mail_recive_flg: obj },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateMailmagazine", obj);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  sendNickname(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/nickname_remind",
        params: { nickname: obj },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  sendPass(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/mail_remind",
        params: { email: obj },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  withDrawal(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/retire",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: {
          mail: obj.mail,
          password: obj.pass,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //田海追加
  inputInquiry(context, obj) {
    context.commit("updateContact", obj);
  },
  sendInquiry(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/inquiry",
        params: {
          email: obj.email,
          // type: obj.type,
          body: obj.body,
          nickname: obj.nickname,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  reqSelectedMotor(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resoleve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/selected_motors",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          if (response.data.result) {
            context.commit("updateSelectMotor", response.data.motors);
          }
          resoleve(response);
        })
        .catch((error) => reject(error));
    });
  },
  reqMotorList(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/motors",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          if (response.data.result) {
            response.data.motors.sort(function (a, b) {
              return a.mno - b.mno;
            });
            context.commit("updateMotorList", response.data.motors);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  registMotor(context, obj) {
    const uri = context.state.apiEndpoint;
    if (!obj.comment) {
      obj = {
        motor_no: obj.mno,
      };
    } else {
      obj = {
        motor_no: obj.mno,
        pickup: obj.pickup,
        comment: obj.comment,
      };
    }
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/motor_regist",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: obj,
      })
        .then((response) => {
          if (response.data.result) {
            context.dispatch("reqSelectedMotor");
            // context.commit("updateSelectMotor");
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getLastRaceInfo(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/last_race",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          context.commit("updateLastRaceInfo", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getMotorDetail(context, mno) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/motor_history/" + mno,
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          context.commit("updateMotorDetail", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getRoyalUserList(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/royal_users",
      })
        .then((response) => {
          context.commit("updateRoyalUserList", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getRoyalUserDispatch(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/royal_user_selected",
        params: { user_id: obj },
      })
        .then((response) => {
          // if (!response.data.result) {
          //   router.push("/");
          // } else {
          context.commit("updateRoyalUser", response.data.user);
          context.commit("updateRoyalUserSelectMotor", response.data.motors);
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // 倉田
  rankingOwnerInfo(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/owner_ranking",
      })
        .then((response) => {
          context.commit("updateRankingOwnerInfo", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getOwnerDetail(context, ono) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/member_info_by_user_id/" + ono,
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
      })
        .then((response) => {
          if (!response.data.member_info.result) {
            router.push("/");
          } else {
            context.commit("updateOwnerDetail", response.data);
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  rankingMotorInfo(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/motor_ranking",
      })
        .then((response) => {
          context.commit("updateRankingMotorInfo", response.data);
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  rankingWinInfo(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/motor_win_ranking",
      })
        .then((response) => {
          context.commit("updateRankingWinInfo", response.data);
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getInformation(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/information",
        params: { count: 5 },
      })
        .then((response) => {
          context.commit("updateInformation", response.data.info);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  sendQuestionnaire(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/questionnaire",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { answer: obj },
      })
        .then((response) => {
          // context.commit("updateQuestionnaire", response.data.info);
          console.log(response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getOwnerRankingChart(context, member_id) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/point_rank_history",
        params: { id: member_id },
      })
        .then((response) => {
          context.commit("updateOwnerRankingChart", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getRankingChart(context) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: uri + "/api/user/point_rank_history",
      })
        .then((response) => {
          context.commit("updateRankingChart", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  registRoyalUser(context, obj) {
    const uri = context.state.apiEndpoint;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: uri + "/api/user/match_user",
        headers: {
          "X-MOG-TOKEN": context.state.token,
        },
        params: { match_user_id: obj },
      })
        .then((response) => {
          context.commit("updateRegistRoyalUser", response.data.info);
          console.log(response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getMemberInfo: (state) => {
    return state.memberInfo;
  },
  getPass: (state) => {
    return state.pass;
  },
  getIsLogedIn: (state) => {
    return state.islogedIn;
  },
  // getRoyalComment: (state) => {
  //   return state.royalComment;
  // },
  getRankingOwnerInfo: (state) => {
    return state.ownerRanking;
  },
  getOwnerDetail: (state) => {
    return state.ownerDetail;
  },
  getRankingMotorInfo: (state) => {
    return state.motorRanking;
  },
  getWinRankingInfo: (state) => {
    return state.winRanking;
  },
  //田海
  getRaceInfo: (state) => {
    return state.raceInfo;
  },
  getMotorInfo: (state) => {
    return state.mortorList;
  },
  getMotorDetailInfo: (state) => {
    return state.motorDetail;
  },
  getSeletedMotor: (state) => {
    return state.selectedMotor;
  },
  getIcon: (state) => {
    return state.icon;
  },
  getMotorImg: (state) => {
    return state.motorImg;
  },
  getContact: (state) => {
    return state.contact;
  },
  getRoyalUser: (state) => {
    return state.royalUser;
  },
  getRoyalUserSelectMotor: (state) => {
    return state.royalUserSelectMotor;
  },
  getRoyalUserList: (state) => {
    return state.royalUserList;
  },
  getInformation: (state) => {
    return state.information;
  },
  getOwnerChart: (state) => {
    return state.ownerRankingChart;
  },
  getChart: (state) => {
    return state.rankingChart;
  },
  getRegistRoyalUser: (state) => {
    return state.registUser;
  },
};
export default createStore({
  state: initialState,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {},
  plugins: [createPersistedState({ key: "MOG" })],
});
